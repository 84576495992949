import {
  minWidth,
  maxWidth
} from "./breakpointUtils"

export const queryBreakpoints = (stylesheetBreakpoints) => ({
  maxWidth: ({ width, name }) => (
    maxWidth ({ width, name, stylesheetBreakpoints })
  ),
  minWidth: ({ width, name }) => (
    minWidth ({ width, name, stylesheetBreakpoints })
  )
})

export * from "./breakpointUtils"