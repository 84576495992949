import React from 'react';
import classnames from "classnames/bind"
import {
  HashRouter as Router,
  Route,
  Redirect
} from 'react-router-dom';
import {
  TransitionMotion,
  spring
} from 'react-motion';
import scroll from 'scroll';
import ProgressManager from 'lib/progress-manager';
import Header from 'components/header'
import * as env from "lib/env"
import { SizeProvider } from "components/size-context"

// IMPORT BEFORE PAGES such that pages can overrides styles using cascading logic
import stylesheet from "./index.module.scss"

// Pages
import Home from 'pages/home';
import BrandHistory from 'pages/brand-history';
import ImplicitPanis from 'pages/implicit-panis';
import DayInTheLife from 'pages/day-in-the-life';
import Lecture from 'pages/lecture';
import ColumbusJournal from 'pages/columbus-journal';
import Statement from 'pages/statement';
import Resources from 'pages/resources';

const cx = classnames.bind(stylesheet)
const BASE = '/';

const pages = [
  { name: 'Shinola Brand History', url: '/brand-history',
    component : BrandHistory },
  { name: 'The Implicit Panis', url: '/implicit-panis',
    component : ImplicitPanis },
  { name: 'A Day in the Life of Panis', url: '/day-in-the-life',
    component : DayInTheLife },
  { name: 'Panis Lecture', url: '/lecture',
    component : Lecture },
  { name: 'Christopher Columbus Journal', url: '/christopher-columbus-journal',
    component : ColumbusJournal},
  { name: 'RETHINK SHINOLA Statement', url: '/statement',
    component : Statement },
  { name: 'resources', url: '/resources',
    component : Resources }
]

class App extends React.Component {
  constructor () {
    super ();

    this.progressManager = new ProgressManager ({
      pages : pages.map (({name}) => name)
    });

    window.getProgress = this.progressManager.getProgress;
  }

  setAppRef = (reference) => {
    this.appDomRef = reference;
  }

  isWindowLargeEnough ({innerWidth, innerHeight}) {
    return innerWidth * innerHeight > 546000 &&
      innerWidth > 800 &&
      innerHeight > 550;
  }

  transitionToStyle ({style : { translate }}) {
    const translateY = translate * 5;
    const opacity = 1 - Math.abs (translate / 100);
    return {
      transform: `translateY(${translateY}px)`,
      opacity : opacity
    }
  }

  navigate (history, to) {
    history.replace (to);
    if (this.appDomRef !== undefined && this.appDomRef.scrollTop > 0) {
      const options = { duration : 1000 };
      scroll.top (this.appDomRef, 0, options);
    }
  }

  nextPage (props) {
    const nextPageIdx = this.progressManager.getProgress ();

    if (nextPageIdx === -1) {
      this.props.onRenderPage.forEach (fn => { fn (BASE) });
      return <Home {...props} pages={pages}/>
    }

    return <Redirect to={pages[nextPageIdx]['url']} />
  }

  renderPage (Page, key, array, {history, match}) {
    const to = key + 1 < array.length ? array[key + 1]['url'] : BASE;
    const pageName = array[key]['name'];

    const onComplete = () => {
      this.progressManager.complete (pageName);
      this.navigate (history, to);
    }

    if (match && match.isExact) {
      this.props.onRenderPage.forEach (fn => { fn (match.url) })
    }

    const transitionStyles = match && match.isExact ? [{
      key : `$z{key}`,
      style : { translate : spring (0, { stiffness : 60, damping : 15 }) }
    }] : [];

    return (
      <TransitionMotion
        styles={transitionStyles}
        willEnter={ () => ({translate :  100}) }
        willLeave={ ({style}) => {
            const translate = { ...style.translate, val : -100 }
            return ({...style, translate})
          }}>
        { ([transitionStyle]) => (transitionStyle ?
          <Page
            onComplete={onComplete}
            style={this.transitionToStyle (transitionStyle)}
          /> : null
        ) }
      </TransitionMotion>
    );
  }

  render () {
    const Component =
      (
        <SizeProvider>
          <Router>
            <div className={cx(`app`)} ref={this.setAppRef}>
              <Header/>
              <Route exact
                path={BASE}
                render={props => this.nextPage (props)}/>
              { pages.map (({url, component}, key, array) => (
                <Route key={key} path={url}>
                  { this.renderPage.bind (this, component, key, array) }
                </Route>
              ) ) }
            </div>
          </Router>
        </SizeProvider>
      );

    return Component;
  }
}

App.defaultProps = {
  mobile: false,
  onRenderPage: []
}


export default App;