import React, { useState, useEffect } from "react"
import { SizeContext } from "./size-context"

export const SizeProvider = ({ children }) => {
  const [ size, setSize ] = useState(undefined)
  const remeasure = ({ innerWidth, innerHeight }) => {
    setSize({ width: innerWidth, height: innerHeight })
  }
  useEffect(() => {
    remeasure(window)
    window.addEventListener("resize", remeasure)

    // Remove the event listener after
    return (
      () => { window.removeEventListener("resize", remeasure);}
    )
  }, [])

  return (
    <SizeContext.Provider value={size}>
      {children}
    </SizeContext.Provider>
  )
}