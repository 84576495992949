import React from 'react';
import resourceList from 'content/data/resources.json';
import classnames from "classnames/bind"

// IMPORT LAST to benefit from cascading style overrides
import stylesheet from "./index.module.scss"
const cx = classnames.bind(stylesheet)

class Resources extends React.Component {
  constructor () {
    super ();

    this.resource = this.resource.bind (this);
    this.category = this.category.bind (this);
    this.mapToCatagories = this.mapToCatagories.bind (this);
    this.complete = this.complete.bind (this);
  }

  resource ({link, description}, key) {
    const Container = ({children, link}) => (
      link && link.length ?
        <a href={link} target="_blank" rel="noopener noreferrer">{children}</a> :
        <span>{children}</span>
    );

    return (
      <li className='resources--resource' key={key}>
        <Container link={link}>{description}</Container>
      </li>
    );
  }

  category (category, key) {
    let resourceElements = category.resources.map (this.resource);

    return (
      <section className={cx`category`} key={key}>
        <h3>{category.title}</h3>
        <ul>{resourceElements}</ul>
      </section>
    )

  }

  mapToCatagories (categoryList, resource) {
    let category = categoryList.find (({title}) => {return title === resource.category});
    if (category === undefined) {
      category = { title: resource.category, resources : []};
      categoryList.push (category);
    }
    category.resources.push (resource);
    return categoryList;
  }

  complete () {
    if (typeof this.props.onComplete === 'function') {
      this.props.onComplete ();
    }
  }

  render () {
    const catagories = resourceList.reduce (this.mapToCatagories, []);
    const categoryElements = catagories.map (this.category);
    const remadeLink = "http://remadeco.org/";

    return (
      <main
        className={cx(`page`,`rethink`)}
        style={this.props.style}
      >
        <div className={cx(`container`)}>
          <section className={cx(`about`)}>
            <h3>About Rethink Shinola</h3>
            <p>Rebekah Modrak is an artist and writer who creates critical interventions into capitalism to critique consumer culture and brand messaging. She is the lead author of <i>Reframing Photography</i> (Routledge 2011), a book analyzing issues in photography such as representation, re-enactment, and mediated vision. She is the creator of the artwork <a target="_blank" rel="noopener noreferrer" href={remadeLink}>Re Made Company</a>, which operates at the intersection of art, activism, media literacy, and consumer culture to analyze and critique how brands use media to create myths of working-class identity. She is an Associate Professor in the Stamps School of Art & Design at the University of Michigan.
            </p>
          </section>
          <section className={cx(`acknowledgements`)}>
            <h3>Acknowledgements</h3>
            <p>Thank you to the many artists, activists, historians, and scholars who reviewed this artwork in progress and offered feedback that informed and enriched the work. A special thank you to David Crockett, Rich Feldman, Jamon Jordan, Kate Levy, and Robin Means Coleman.
            </p>
            <p>
              Web design for RETHINK SHINOLA by Sam Oliver.
            </p>
          </section>
          {categoryElements}
          <div className={cx(`complete-container`)}>
            <div
              className={cx(`complete`,`button`)}
              onClick={this.complete}
            >
              complete
            </div>
          </div>
        </div>
      </main>
    )
  }
}

export default Resources;