import React from 'react';
import YouTube from 'react-youtube'
import classnames from "classnames/bind"

// IMPORT LAST to benefit from cascading style overrides
import stylesheet from "./index.module.scss"
const cx = classnames.bind(stylesheet)

class ImplicitPanis extends React.Component {
  constructor (props) {
    super (props);

    this.complete = this.complete.bind (this);
  }

  complete () {
    if (typeof this.props.onComplete === 'function') {
      this.props.onComplete ();
    }
  }

  render () {
    const embedOptions = {
      width: 1280,
      height: 720,
      playerVars : {
        autoplay : 1,
        color : 'white',
        fs : 0,
        iv_load_policy : 3,
        rel : 0,
        showinfo : 0
      }
    }

    return (
      <main
        className={cx(`page`)}
        style={this.props.style}
      >
        <div className={cx(`container`)}>
          <YouTube
            videoId="ox7Br8b28ng"
            onEnd={this.complete}
            opts={embedOptions}
            className={cx(`youtube`)}
          />
        </div>
      </main>
    )
  }
}

export default ImplicitPanis;