import React from 'react';
import { Link } from 'react-router-dom';
import classnames from "classnames/bind"

// IMPORT LAST to benefit from cascading style overrides
import stylesheet from "./index.module.scss"
const cx = classnames.bind(stylesheet)

const Home = ({ pages }) => {
  const links = pages.map (({ name, url }, index) => (
    <li className={cx(`link`)} key={index}>
      <Link to={url}>
        <div className={cx("button")}>
          {name}
        </div>
      </Link>
    </li>
  ));

  return (
    <main className={cx(`page`,`rethink`)} /*style={this.props.style}*/>
      <div className={cx(`container`)}>
        <ul className={cx(`page-list`)}>
          {links}
        </ul>
        <ul className={cx(`social-media`)}>
          <li>
            <a
              className={cx(`button`)}
              href="https://twitter.com/RethinkShinola"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
          </li>
        </ul>
      </div>
    </main>
  )
}

export default Home;