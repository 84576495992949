import React from 'react';
import classnames from 'classnames';

const Logo = ({className, style}) => (
  <svg
    viewBox="0 0 128 74.381"
    style={style}
    className={classnames(className)}>
    <g>
      <path id="path3340_7_" fill="#030303" d="M3.145,47.58c1.137-1.717,3.138-2.641,5.118-2.872
        c2.389-0.276,4.907,0.036,7.037,1.22c0.82,0.443,1.542,1.043,2.214,1.689c-1.206,1.211-2.427,2.412-3.655,3.602
        c-1.087-1.119-2.653-1.634-4.175-1.667c-0.615,0.013-1.238,0.208-1.732,0.583c-0.585,0.453-0.901,1.31-0.601,2.017
        c0.245,0.632,0.87,1.03,1.509,1.165c2.132,0.438,4.436,0.453,6.334,1.651c1.279,0.756,2.105,2.134,2.378,3.586
        c0.316,1.696,0.262,3.536-0.532,5.097c-0.843,1.698-2.39,2.949-4.112,3.645c-1.561,0.65-3.267,0.837-4.942,0.787
        c-2.545-0.025-5.222-0.758-7.023-2.685c1.14-1.374,2.358-2.679,3.539-4.017c1.209,1.113,2.889,1.484,4.475,1.535
        c0.951-0.024,1.994-0.092,2.78-0.696c0.85-0.644,0.902-2.038,0.202-2.811c-0.587-0.515-1.408-0.511-2.131-0.632
        c-1.645-0.172-3.345-0.404-4.79-1.281c-1.508-0.861-2.563-2.434-2.864-4.154C1.852,51.395,2.009,49.246,3.145,47.58L3.145,47.58z"
        />
      <path id="path3342_7_" fill="#030303" d="M119.083,44.895c-3.347,0.192-6.689,0.418-10.035,0.6
        c-0.009,1.398-0.003,2.796-0.005,4.195c1.441-0.029,2.881-0.113,4.322-0.156c-2.097,5.869-4.225,11.725-6.43,17.553
        c1.89,0.098,3.775,0.237,5.666,0.31c0.376-1.067,0.668-2.171,1.104-3.214c2.222,0.065,4.444,0.152,6.663,0.289
        c0.348,1.16,0.684,2.325,1.013,3.492c1.886,0.135,3.772,0.271,5.658,0.418C124.482,60.519,121.814,52.695,119.083,44.895z
         M117.126,53.694c0.629,1.978,1.202,3.974,1.822,5.954c-1.256,0.01-2.51-0.037-3.768-0.035
        C115.843,57.645,116.464,55.662,117.126,53.694z"/>
      <path id="path3344_7_" fill="#030303" d="M20.524,45.587c1.801,0.055,3.597,0.179,5.397,0.239
        c-0.001,2.688,0.006,5.377-0.003,8.066c1.897,0.125,3.804,0.035,5.704,0.1c0.003-2.637-0.004-5.274,0.003-7.912
        c1.799,0.055,3.597,0.174,5.397,0.181c0.021,6.714-0.002,13.429,0.013,20.146c-1.802,0.141-3.609,0.087-5.409,0.242
        c-0.01-2.723,0-5.445-0.004-8.17c-1.9,0.005-3.801,0.065-5.701,0.071c0.003,2.766-0.003,5.531,0.003,8.297
        c-1.801,0.13-3.605,0.2-5.406,0.279C20.526,59.946,20.517,52.767,20.524,45.587L20.524,45.587z"/>
      <path id="path3346_7_" fill="#030303" d="M92.287,46.245c1.802-0.033,3.599-0.173,5.402-0.204
        c-0.003,5.35,0.008,10.699-0.006,16.051c1.218,0.131,2.449,0.064,3.672,0.122c1.46,0.006,2.918,0.112,4.377,0.118
        c-0.005,1.562-0.002,3.121-0.001,4.682c-4.478-0.161-8.948-0.449-13.429-0.526C92.271,59.74,92.3,52.992,92.287,46.245
        L92.287,46.245z"/>
      <path id="path3348_7_" fill="#030303" d="M41.634,46.455c1.797-0.007,3.593,0.08,5.391,0.084
        c-0.004,6.555,0.023,13.11-0.014,19.666c-1.794-0.029-3.586,0.066-5.377,0.064C41.64,59.666,41.636,53.061,41.634,46.455z"/>
      <path id="path3350_7_" fill="#030303" d="M80.662,46.293c-0.67,0.008-1.342,0.068-1.998,0.156
        c-2.178,0.337-4.257,1.508-5.558,3.33c-1.238,1.839-1.239,4.166-1.218,6.305c0.005,2.308-0.161,4.816,1.105,6.855
        c1.148,1.731,3.016,2.922,5.01,3.392c1.631,0.37,3.334,0.372,4.973,0.05c1.753-0.359,3.375-1.334,4.509-2.739
        c1.008-1.276,1.344-2.941,1.426-4.538c0.062-1.771,0.062-3.545,0-5.317c-0.073-1.157-0.239-2.335-0.733-3.394
        c-0.647-1.425-1.891-2.486-3.239-3.202C83.617,46.499,82.138,46.273,80.662,46.293z M80.41,50.678c0.76-0.013,1.527,0.22,2.1,0.736
        c0.66,0.646,0.754,1.623,0.84,2.5c0.1,1.683,0.102,3.372,0,5.055c-0.085,0.844-0.166,1.783-0.783,2.421
        c-1.27,1.259-3.766,1.069-4.652-0.558c-0.406-0.847-0.406-1.813-0.479-2.731c-0.007-1.772-0.113-3.569,0.223-5.318
        c0.152-0.917,0.851-1.696,1.736-1.939C79.718,50.74,80.062,50.684,80.41,50.678L80.41,50.678z"/>
      <path id="path3352_7_" fill="#030303" d="M51.691,46.653c1.576,0.004,3.152,0.016,4.729,0.044
        c2.306,3.217,4.636,6.414,6.949,9.624c0.023-3.188,0.008-6.379,0.008-9.567c1.819-0.018,3.641-0.023,5.461,0.003
        c-0.023,6.41-0.008,12.823-0.008,19.235c-1.592-0.025-3.182-0.001-4.771-0.015c-2.309-3.208-4.631-6.405-6.958-9.601
        c-0.013,3.208-0.004,6.415-0.004,9.623c-1.8,0.062-3.601,0.056-5.402,0.095C51.689,59.613,51.697,53.133,51.691,46.653
        L51.691,46.653z"/>
    </g>
    <g>
      <path fill="#030303" d="M0.962,6.051h9.557c1.227,0,2.299,0.087,3.22,0.26c0.92,0.173,1.686,0.484,2.299,0.933
        c0.614,0.449,1.074,1.08,1.38,1.892s0.46,1.856,0.46,3.134v7.203c0,1.21-0.223,2.135-0.665,2.773
        c-0.443,0.64-1.159,1.148-2.146,1.528c1.192,0.553,1.958,1.174,2.3,1.865c0.34,0.69,0.511,1.692,0.511,3.005v11.971h-7.258V27.763
        c0-0.689-0.11-1.173-0.332-1.45c-0.223-0.276-0.673-0.415-1.355-0.415H8.219v14.717H0.962V6.051z M8.219,10.767v10.571H9.19
        c0.579,0,0.963-0.138,1.15-0.414c0.187-0.277,0.281-0.743,0.281-1.4v-6.942c0-0.761-0.102-1.253-0.306-1.479
        c-0.205-0.224-0.682-0.336-1.431-0.336H8.219z"/>
      <path fill="#030303" d="M33.771,6v4.974h-4.497v8.55h4.497v4.612h-4.497v11.865h4.497v4.613H22.017V6H33.771z"/>
      <path fill="#030303" d="M51.658,6.051v4.612h-3.73v29.951h-7.257V10.663h-3.782V6.051H51.658z"/>
      <path fill="#030303" d="M54.725,6.051h7.256v13.37h2.453V6.051h7.257v34.563h-7.257V24.032h-2.453v16.582h-7.256V6.051z"/>
      <path fill="#030303" d="M75.831,6.051h7.257v34.563h-7.257V6.051z"/>
      <path fill="#030303" d="M105.317,40.614h-7.461l-4.14-17.929v17.929h-6.491V6.051h7.563l4.038,16.737V6.051h6.49V40.614z"/>
      <path fill="#030303" d="M126.168,6.051l-3.833,15.028l4.703,19.535h-7.155l-3.22-16.064v16.064h-7.257V6.051h7.257v13.473
        l2.607-13.473H126.168z"/>
    </g>
    <rect fill="#231F20" width="128" height="2"/>
    <rect y="72.381" fill="#231F20" width="128" height="2"/>
  </svg>
)

export default Logo;