export const localizeBreakpoint = (bp) => {
  return parseInt(bp)
}

export const localizeBreakpoints = (stylesheetBreakpoints) => {
  const breakpointNames = Object.keys(stylesheetBreakpoints)
  return breakpointNames.reduce((localBreakpoints, breakpointName) => {
    return {
      ...localBreakpoints,
      [breakpointName]: (
        localizeBreakpoint(stylesheetBreakpoints[breakpointName])
      )
    }
  }, {})
}

// Assumes that the stylesheetBreakpoints object is sorted in increasing size order
export const minWidth = ({ stylesheetBreakpoints, width, name }) => (
  width >= localizeBreakpoint(stylesheetBreakpoints[name])
)

export const maxWidth = ({ stylesheetBreakpoints, width, name }) => {
  console.log(width)
  return (
  width <= localizeBreakpoint(stylesheetBreakpoints[name])
)}
