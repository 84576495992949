function toBoolean (environmentVariable = { value: undefined, defaultValue: undefined }) {
  const isShortcut = environmentVariable === "string"
  const value = isShortcut ? environmentVariable : environmentVariable.value
  switch (value) {
    case "true":
      return true
    case "false":
      return false
    default:
      return isShortcut ? undefined : environmentVariable.defaultValue
  }
}

export const gaId = process.env.REACT_APP_GA_ID || ""

export const isTracking = toBoolean({
  value: process.env.REACT_APP_TRACKING,
  defaultValue: false
})

export const isMobileEnabled = toBoolean({
  value: process.env.REACT_APP_MOBILE,
  defaultValue: false
})