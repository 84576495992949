import React from 'react';
import classnames from "classnames/bind"
import Logo from 'components/logo';

// IMPORT LAST to benefit from cascading style overrides
import stylesheet from "./index.module.scss"
const cx = classnames.bind(stylesheet)

const Header = ({className, id}) => (
  <header className={cx(className, "header")} id={id}>
    <div className={cx("logo-container")}>
      <Logo className={cx('logo')}/>
    </div>
  </header>
)

export default Header;