import React, { useContext } from "react"
import { SizeContext } from "./size-context"

const defaultOptions = {
  deferRender: false
}

export const useSize = () => {
  const size = useContext(SizeContext)

  return size
}

export const SizeWrapper = ({ children, options }) => {
  const size = useSize()
  const { deferRender } = { ...defaultOptions, ...options }
  if (size == null && deferRender) return null

  return children({ size })
}

export const withSize = (Component, options) => {
  const { deferRender } = { ...defaultOptions, ...options }
  return (props) => {
    const size = useSize()
    if (size == null && deferRender) return null

    return <Component size={size} {...props} />
  }
}