import React from 'react';
import ReactDOM from 'react-dom';
import MobileDetect from 'mobile-detect';
import ga from 'universal-ga';
import * as serviceWorker from "./serviceWorker"

// IMPORT FIRST to allow elements to override resets using cascading logic
import "./style/index.scss"

import * as env from "./lib/env"
import App from 'app';


// Get device settings for app
let device = new MobileDetect(window.navigator.userAgent);
let isMobile = device.mobile () !== null;
let isTablet = device.tablet () !== null;

// Initialize Google Analytics
if (env.isTracking) {
  ga.initialize (env.gaId);
}

// Setup the App's options
const appOptions = {
  mobile: (isMobile && !isTablet),
  onRenderPage: [ga.pageview.bind (ga)]
}

if (document.rethinkShinolaIsReady) {
  ReactDOM.render(<App {...appOptions} />, document.getElementById ('root'));
} else {
  document.rethinkShinolaIsReady = true
  document.rethinkShinolaOnReady = () => {
    console.log("rawr")
    ReactDOM.render(<App {...appOptions} />, document.getElementById ('root'));
  }
  console.log("rawr")
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
