import Cookies from 'cookies-js';

class ProgressManager {

  constructor ({ pages = [] }) {
    this.pages = pages.map ((pageName) => {
      const encodedName = this.encodePageName (pageName);
      const visited = this.strToBool (Cookies.get (encodedName) || 'false');
      return { page: encodedName, visited:  visited }
    });

    this.complete = this.complete.bind (this);
    this.getProgress = this.getProgress.bind (this);
  }

  encodePageName (name) {
    return name.replace (/\s+/, '-');
  }

  complete (pageName) {
    const encodedName = this.encodePageName (pageName);
    Cookies.set (encodedName, true);
    this.pages.find (
      ({page}) => page === encodedName)['visited'] = true;
  }

  getProgress () {
    return this.pages.findIndex (({visited}) => !visited);
  }

  strToBool(str) {
    var bool;
    if (str.match(/^(true|1|yes)$/i) !== null) {
        bool = true;
    } else if (str.match(/^(false|0|no)*$/i) !== null) {
        bool = false;
    } else {
        bool = null;
        if (console) console.log('"' + str + '" is not a boolean value');
    }
    return bool;
  }
}

export default ProgressManager;