import React from 'react';
import YouTube from 'react-youtube'
import classnames from "classnames/bind"

// IMPORT LAST to benefit from cascading style overrides
import stylesheet from "./index.module.scss"
const cx = classnames.bind(stylesheet)

const timesAds = [
  require ('content/images/murphy1.jpg'),
  require ('content/images/murphy2.jpg'),
  require ('content/images/murphy3.jpg'),
  require ('content/images/murphy4.jpg')
]

const illustration = require ('content/images/aristocrat.jpg');
const caudrill = [
  require ('content/images/caudrill_1.jpg'),
  require ('content/images/caudrill_2.jpg'),
  require ('content/images/caudrill_3.jpg')
]

const mantanYoutubeLink = "Iep4Zyinkjk";
const embedOptions = {
  width: 1280,
  height: 720,
  playerVars : {
    color : 'white',
    fs : 0,
    iv_load_policy : 3,
    rel : 0,
    showinfo : 0
  }
}

const hookLink = "https://de.ryerson.ca/DE_courses/uploadedFiles/6052_Arts/CSOC202/Modules/Module_00/eating%20the%20other.pdf";
const murphyLink = "https://fashionista.com/2014/03/bruce-weber-celebrates-detroit-in-shinolas-first-fashion-campaign";
const blankLink = "https://de.ryerson.ca/DE_courses/uploadedFiles/6052_Arts/CSOC202/Modules/Module_00/eating%20the%20other.pdf";
const successStoryLink = "https://www.youtube.com/watch?v=SPJ5T8Ilh7M";

class Statement extends React.Component {
  constructor () {
    super ();

    this.complete = this.complete.bind (this);
  }

  complete () {
    if (typeof this.props.onComplete === 'function') {
      this.props.onComplete ();
    }
  }

  render () {

    return (
      <main className={cx(`page`,`rethink`)} style={this.props.style}>
        <div className={cx(`container`)}>
          <h1 className={cx(`title`)}>RETHINK SHINOLA</h1>
          <p>
            GMC’s promotional video “Jacques Panis on Shinola’s Quest to Revive American Manufacturing” offers a peek into a day-in-the-life of Jacques Panis, the President of Shinola. Panis lived and worked in Texas until the venture capital firm Bedrock Manufacturing (based in Dallas, Texas) commissioned a focus group revealing that consumers would pay three times as much for a pen manufactured in Detroit as in New York or China. Bedrock also noticed that the mainstream media gives free and ample coverage to [White] start-ups in Detroit. Free publicity for being White and privileged. Bedrock birthed Shinola in Texas with an express ticket to Detroit. Jacques Panis followed, adopting Detroit as his “community” without a westward look back. Capitalizing on the city’s mythos, the company uses images of African American assembly workers to validate and fortify their appropriation of the name Detroit. Referring to Detroit as “the wild west” and their venture there as “crazy,” Panis and Shinola exploit White fantasies about the Other to sell $550 - $1325 watches and $1000 - $2950 bikes
          </p>
          <p>
            bell hooks writes: “
            <a target="_blank" rel="noopener noreferrer" href={hookLink}>
              Certainly from the standpoint of white supremacist capitalist patriarchy, the hope is that desires for the “primitive” or fantasies about the Other can be continually exploited, and that such exploitation will occur in a way that reinscribes and maintains the status quo.
            </a>
            ”
          </p>
          <p>
            GMC’s day-in-the-life of Panis shows a glimpse into White privilege cast larger than life, as myth. LIFE and other picture magazines introduced the “day-in-the-life” format in the 1920s to tell stories in such detail that they must be perceived as true. This appeals to Shinola, which is, at its heart, a company with conventional, archaic values related to the representations of whiteness and African Americans. In this video and in his other presentations, Panis casts himself as a White savior, and colonizer of Detroit. Shinola’s promotional materials subtly — and not so subtly — recall earlier images associated with racism. For an early ad campaign, Shinola commissioned Bruce Weber to photograph White, blond supermodel Carolyn Murphy (
              <a target="_blank" rel="noopener noreferrer" href={murphyLink}>
                in her first visit to Detroit
              </a>
              ) hugging and bicycling with African American Detroiters. Photographed in black and white, dressed in clothing that could easily be mistaken for Jim Crow period fashions, the images elicit an imperialist nostalgia for a “glorious” past of White patronage and the benevolent White mistress. When hands are laid on another body in these images, they are Ms. Murphy’s hands.
          </p>
          <div className={cx(`ad-container`)}>
            <img src={timesAds[0]} className={cx(`times-ad-1`)}/>
            <img src={timesAds[2]} className={cx(`times-ad-2`)}/>
            <img src={timesAds[3]} className={cx(`times-ad-3`)}/>
            <img src={timesAds[1]} className={cx(`times-ad-4`)}/>
            <img src={illustration} className={cx(`illustration`)}/>
          </div>
          <p>
            In their glossy advertising campaigns and full-page <i>New York Times</i> ads, Shinola claims deep connection with Detroit and its citizens, often portraying images of African American employees as grateful, servile, non-threatening or soulful. Meant to prove the company’s authentic Detroit identity and to “
            <a target="_blank" rel="noopener noreferrer" href={blankLink}>
              enhance the blank landscape of whiteness,
            </a>
            ” they ultimately assert White mastery over blackness and market paternalism as “community engagement.” Once again, African Americans are not in control of their own portrayal in media. Instead, their identity is constructed by and presented for the benefit and consumption of Whites. That identity becomes used as symbols of authenticity and the resilience of Detroit, but those imaged are not full participants of, leaders within, or owners of this system.
          </p>
          <p>
            A Crane.tv promotional video (“
            <a target="_blank" rel="noopener noreferrer" href={successStoryLink}>
              Shinola: American Success Story
            </a>
            ”) shows Shinola Creative Director Daniel Caudill introducing Shinola’s London store and explaining that this store is a “gateway to the world,” with Bruce Weber’s images representing Shinola’s American values to an international audience.
          </p>
          <div className={cx(`caudrill`)}>
            <img src={caudrill[0]} className={cx(`caudrill-1`)}/>
            <img src={caudrill[1]} className={cx(`caudrill-2`)}/>
            <img src={caudrill[2]} className={cx(`caudrill-3`)}/>
          </div>
          <p>
            In Spike Lee’s satirical film <i>Bamboozled</i>, a television executive brings in a media consultant to prepare for potential backlash to their unexpectedly successful contemporary televised minstrel show: <i>Mantan: The New Millennium Minstrel Show</i>.
          </p>
          <h5>
            The crisis control strategies are:
          </h5>
          <ol>
            <li>
              “Lighten up, this is about fun.”  [Shinola: “This is about jobs.” or “We’re being positive about Detroit.”]
            </li>
            <li>
              “We gainfully employ African Americans in front of and behind the camera. We got to cover ourselves people. I’m talking, I need a black grip, a black gaffer, a black P.A. Have them there.”
            </li>
            <li>
              “Let the audience decide.”
            </li>
            <li>
              “Who put these critics in charge anyway? These so-called cultural police.”
            </li>
            <li>
              “Who determines what is Black?”
            </li>
            <li>
              “Invoke the spirit of Dr. Martin Luther King.” [Shinola has produced Muhammad Ali, Jackie Robinson, and Maya Angelou watches.]
            </li>
            <li>
              “Use the word <i>community</i> often when talking about <i>Mantan</i>.”
            </li>
          </ol>
          <p>
            In addition to those striking parallels between Shinola [as evidenced by the Shinola Lecture in particular] and <i>Bamboozled</i>, the Mantan Manifesto scene begins with a shot of President Bill Clinton in the White House, watching the Mantan show, clapping, laughing, and saying, “I like this!” Shinola regularly hypes President Clinton’s 2014 visit to the Shinola “factory,” where he was photographed touring the assembly line and as an African American employee gifted him a watch.
          </p>
          <div className={cx(`mantan-container`)}>
            <YouTube
              videoId={mantanYoutubeLink}
              opts={embedOptions}
              className={cx(`mantan`)}
            />
          </div>
          <p>
            Those who see Shinola as a positive sign of Detroit’s revival and a benefit to all (”they’re creating jobs”) fail to consider the profound price of marketing blackness and appropriating the cultural history of Detroit. African Americans were brought to the United States as slaves for “jobs,” and the narrative of “being fortunate to have wage labor” has continued since then.
          </p>
          <p>
            Treating Detroiters as bodies to serve the interests of White capitalists is the same vision presented by Christopher Columbus. Just as Panis encouraged University of Michigan students to move to Detroit, advising them to consider, “how do you get those people to be your ambassadors,” Columbus landed in the Americas and noted, “It appears to me, that the people are ingenious, and would be good servants.” African American Detroiters should be the beneficiaries of resources and investments in order to build a city of Black-owned enterprises, to develop skills, livelihoods and visions through self-determination.
          </p>
          <p>
            Shinola builds on the history of racist policies that has systematically devalued and undermined African Americans’ accumulation of wealth and power, including:
          </p>
          <ul>
            <li>
              the illegal ownership of African and African American people as slaves in the late 18th and early 19th century by Detroit “leaders” like Lewis Cass;
            </li>
            <li>
              fees and curfews that applied only to African Americans — such as a law in 1827 requiring all “Negroes coming to Detroit” to pay a $500 fee; or, in recent years, illegal property taxes for 55% and 85% of Detroit properties, assessed at more than 50% of market value;
            </li>
            <li>
              housing discrimination and segregation that created inequity in wealth and property through racially restrictive covenants prohibiting renting or selling to African Americans or, as with the Federal Housing Act of 1934, by subsidizing only Whites’ homes and mortgages;
            </li>
            <li>
              targeted police assaults terrorizing African American Detroiters, as with the Big Four, a 1960s team of four police that randomly stopped and harassed Black residents, and S.T.R.E.S.S., a police unit that operated as a “murder squad” from 1970-74, fatally shooting twenty-four men, twenty-two of them African American, within three and a half years;
            </li>
            <li>
              disparity in education and disinvestment in Detroit public schools;
            </li>
            <li>
              and the systematic obliteration of working class and middle class communities, such as the dislocation of Detroit African Americans from Black Bottom and the destruction of over 350 thriving Black-owned businesses (without compensation) in Paradise Valley in the name of “urban renewal.”
            </li>
          </ul>
          <p>
            RETHINK SHINOLA is not a critique of the Shinola employee who works for an income to make a living, but a critique of the company and the system that expropriates “community,” colonizes a city and employees as their brand narrative, and devours the resources of that community for private gain, while trying to market themselves as a “philanthropist.”
          </p>
          <p>
            RETHINK SHINOLA is a critique of Shinola’s name and brand messaging — their representation of African Americans and the perpetuation of the White Savior myth.
          </p>
          <p>
            RETHINK SHINOLA is not a critique of the company’s operations, though this bears criticism and should be studied and considered as Shinola’s appropriation of Detroit and blackness negatively impact the economic well-being of Detroiters. Since opening Shinola, Bedrock Manufacturing Co. has raised hundreds of millions of dollars, including over $125 million from “friends and family,” made up of investments from Dan Gilbert’s Rock Ventures LLC and the Kresge Foundation. This is during a time when, according to the most recent census data, 40.3% of Detroit residents live below the federal poverty line – $24,008 for a family of four. African Americans make up 83% of Detroit’s population, yet only 15% of private company revenues. Why? Black businesses were unable to be a major part of the "downtown development" due largely to the destruction of Paradise Valley and the lack of compensation to Black-owned businesses when they lost their businesses through eminent domain for the building of the I-75 & I-375 highway. Now, the private, for profit Shinola company absorbs and exploits resources from institutions like the Kresge Foundation, whose mission is to “deploy an array of grant-making and investing tools to expand opportunities for low-income people,” i.e., economic resources that should go to local Detroiters. Historian Jamon Jordan writes that “There’s a striking contrast between the state and city's response to African American business owners and residents in the lower east side in the 1940s-1960s and their response towards billionaire developers in downtown today.” Jordan describes this as a “cheerfest for businesses like Shinola, Quicken Loans, Little Caesar's and the subsidizing of their development by tax dollars as opposed to the lack of support by city/state officials and tax dollars for Black and local small businesses for decades.”
          </p>
          <p>
            White supremacy is not only about violence towards African Americans; here, Shinola devalues African Americans and advances whiteness within sophisticated graphic design and brand marketing; the assumption of superiority, of dominance and subordination, playing out elegantly within everyday interactions and messages. RETHINK SHINOLA. Detroiters should control the word “Detroit.” African Americans should control their own representations and should define and control resources to build “community.”
          </p>
          <p>
            RETHINK SHINOLA. Stop buying colonialist versions of Detroit and blackness. Do not buy Shinola products.
          </p>
          <div className={cx(`complete-container`)}>
            <div
              className={cx(`complete`,`button`)}
              onClick={this.complete}
            >
              Next
            </div>
          </div>
        </div>
      </main>
    )
  }
}

export default Statement;