import React from 'react';
import {
  TransitionMotion,
  spring
} from 'react-motion';
import ShinolaQuote from 'components/shinola-quote';
import Timer from 'lib/timer'
import classnames from "classnames/bind"
import { withSize } from "components/size-context"
import { queryBreakpoints } from  "lib/queryBreakpoints"
import stylesheetVariables from "style/_variables.scss"

// IMPORT LAST to benefit from cascading style overrides
import stylesheet from "./index.module.scss"
const cx = classnames.bind(stylesheet)

const mediaQuery = queryBreakpoints(stylesheetVariables)

const blackfaceImage = require ('content/images/hist-blackface.png');
const monkeyImage = require ('content/images/hist-monkey.jpg');

const BLACK_FACE = 'blackface';
const PANIS = 'panis';
const MONKEY = 'monkey';

class BrandHistory extends React.Component {
  constructor () {
    super ();

    this.state = {
      display : {
        [BLACK_FACE] : true,
        [PANIS] : false,
        [MONKEY] : false
      }
    }

    this.show = this.setDisplay.bind (this, true);
    this.hide = this.setDisplay.bind (this, false);
    this.timer = new Timer ();
  }

  componentDidMount () {
    const { timer, show, hide, props } = this
    const { size } = props

    this.timer = timer

    this.setSequence ({ timer, show, hide, size });
    this.timer.start ();
  }

  componentWillUnmount () {
    if (this.timer.isOn ()) {
      this.timer.stop ();
    }
  }

  setSequence = ({ timer, show, hide, size}) => {
    const isMobile = mediaQuery.maxWidth({ ...size, name: "bpMobileLarge" })
    console.log("isMobile", isMobile)

    if (isMobile) {
      timer.at (
        { time: 32000, call: () => hide (BLACK_FACE)},
        { time: 32000, call: () => show (PANIS)},
        { time: 40000, call: () => hide (PANIS)},
        { time: 40000, call: () => show (MONKEY)},
        { time: 50000, call: this.complete}
      );
    } else {
      timer.at (
        { time: 32000, call: () => show (PANIS)},
        { time: 40000, call: () => hide (BLACK_FACE)},
        { time: 40000, call: () => show (MONKEY)},
        { time: 50000, call: this.complete}
      );
    }
  }

  setDisplay = (shouldDisplay, title) => {
    let display = this.state.display;
    display[title] = shouldDisplay;
    this.setState (display);
  }

  shouldDisplay = (title) =>{
    return this.state.display[title];
  }

  transitionStyle = (translate) => {
    return {
      transform : `translateY(${translate}%)`,
      opacity : (100 - translate) / 100
    };
  }

  complete = () => {
    this.timer.stop ();
    if (typeof this.props.onComplete === 'function') {
      this.props.onComplete ();
    }
  }

  blackfaceSection = ({translate}) => {
    const mammyBookLink = "https://books.google.com/books/about/Mammy_and_Uncle_Mose.html?id=x_LqAAAAMAAJ";
    return (
      <section
        className={cx(`blackface`)}
        style={this.transitionStyle (translate)}
        key={BLACK_FACE}
      >
        <div className={cx(`blackface-image-container`)}>
          <img
            src={blackfaceImage}
            className={cx(`blackface-image`)}
            alt="blackface"
          />
        </div>
        <div className={cx(`blackface-description`)}>
          <p style={{transform : `translateY(${translate * 4}px)`}}>
            The original Shinola company (1877 to 1960) used blackness to sell shoe polish. Images and advertisements, like this one from the 1920s, spread the myth of the African American as a happy servant. Black shoe polish was also used to blacken the skin of White and Black actors. Wearing blackface, they acted out racist, dehumanizing stereotypes, such as the happy-go-lucky slave on the plantation. These caricatures soothed Whites’ post-slavery racial anxiety and reinscribed their supremacy. Over time, with advances in civil rights, the stereotypes evolved to look more realistic, but
            <a target="_blank" rel="noopener noreferrer" href={mammyBookLink}> the values remain the same</a>.
          </p>
          <p style={{transform : `translateY(${translate * 6}px)`}}>
            In 2001, Bedrock Manufacturing purchased and resurrected the Shinola name and legacy within a city with a population of 83% African Americans; it then used Detroit’s name as branding for their company. The “new” Shinola company also creates representations of whiteness to reinforce their “leadership” and creates and markets images of Black workers being grateful for this so-called leadership. Constructing and controlling images of African Americans is central to racism and maintaining White supremacy.
          </p>
        </div>
      </section>
    )
  }

  panisSection = ({translate}) => {
    return (
      <section
        className={cx(`panis`)}
        style={this.transitionStyle (translate)}
        key={PANIS}>
        <ShinolaQuote
          href="http://www.dbusiness.com/Champions/May-June-2014/Jacques-Panis"
          author="Jacques Panis"
          title="President of Shinola"
          className={cx(`panis-quote`)}
        >
          THERE’S SUCH A WONDERFUL HISTORY AND
          <br/>
          HERITAGE TO THE NAME SHINOLA. IT’S A VERY
          <br/>
          ICONIC BRAND IN THE UNITED STATES.</ShinolaQuote>
      </section>
    );
  }

  monkeySection = ({translate}) => {
    return (
      <section
        className={cx(`monkey`)}
        style={this.transitionStyle (translate)}
        key={MONKEY}
      >
        <div className={cx(`monkey-description`)}>
          <p>Shinola advertisement, date unknown — estimated to be 1960.</p>
        </div>
        <div className={cx(`monkey-image-container`)}>
          <img
            src={monkeyImage}
            className={cx(`monkey-image`)}
            alt="Monkey"
          />
        </div>
      </section>
    );
  }

  getSection = ({key, style}) => {
    switch (key) {
      case BLACK_FACE:
        return this.blackfaceSection (style);
      case PANIS:
        return this.panisSection (style);
      case MONKEY:
        return this.monkeySection (style);
      default:
        return null
    }
  }

  render () {
    const fastAnimateIn = {
      translate : spring (0, { stiffness : 40, damping : 12 })
    };
    const slowAnimateIn = {
      translate : spring (0, { stiffness : 60, damping : 15 })
    };

    const sections = [
      { key : BLACK_FACE, style : fastAnimateIn },
      { key : PANIS, style : slowAnimateIn },
      { key : MONKEY, style : fastAnimateIn }
    ].reduce ((a, b) => {
      return this.shouldDisplay (b.key) ? [...a, b] : a;
    }, []);

    return (
      <TransitionMotion
        styles={sections}
        willLeave={ ({style}) => {
          const translate = { ...style.translate, val : -100 }
          return ({...style, translate})
        }}
        willEnter={ () => ({translate :  100}) }
        defaultStyles={ sections.map (val => (
          {...val, style : {translate : 100} }
        )) }>
        { interpolatedStyles => {
          return (
            <main className={cx(`page`)} style={this.props.style}>
              { interpolatedStyles.map (this.getSection.bind (this)) }
            </main>
          )}
        }
      </TransitionMotion>
    )
  }
}

export default withSize(BrandHistory, { deferRender: true });