import React from 'react';
import journalEntries from 'content/data/columbus-journal.json';
import classnames from "classnames/bind"

// IMPORT LAST to benefit from cascading style overrides
import stylesheet from "./index.module.scss"
const cx = classnames.bind(stylesheet)

class ColumbusJournal extends React.Component {
  constructor () {
    super ();

    this.complete = this.complete.bind (this);
    this.journalEntry = this.journalEntry.bind (this);
  }

  journalEntry ({title, content}, key) {
    const paragraphs = content.split ('\n');
    return (
      <section className={cx(`entry`)} key={key}>
        <h3 className={cx(`title`)}>{title}</h3>
        <div className={cx(`content`)}>
          { paragraphs.map ((text, key) => <p key={key}>{text}</p>) }
        </div>
      </section>
    )
  }

  complete () {
    if (typeof this.props.onComplete === 'function') {
      this.props.onComplete ();
    }
  }

  render () {
    return (
      <main
        className={cx(`page`,`colonial`)}
        style={this.props.style}
      >
        <div className={cx(`journal`)}>
          <h2>Author: Christopher Columbus</h2>
          {journalEntries.map (this.journalEntry)}
          <div className={cx(`complete-container`)}>
            <div
              className={cx(`complete`,`button`)}
              onClick={this.complete}
            >
              Next
            </div>
          </div>
        </div>
      </main>
    )
  }
}

export default ColumbusJournal;