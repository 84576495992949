import React from 'react';
import activities from 'content/data/day-in-the-life.json';
import classnames from "classnames/bind"

// IMPORT LAST to benefit from cascading style overrides
import stylesheet from "./index.module.scss"
const cx = classnames.bind(stylesheet)

class DayInTheLife extends React.Component {
  constructor (props) {
    super (props);

    this.complete = this.complete.bind (this);
  }

  activity (activityData, key) {
    const imgUrl = require (`content/images/${activityData.image}`);
    // const imageStyle = {'backgroundImage' : `url(${imgUrl})`};
    return (
      <li className={cx(`activity`)} key={key}>
        <img
          src={imgUrl}
          className={cx(`activity-image`)}
        />
        <h5 className={cx(`activity-title`)}>
          {activityData.title}
        </h5>
        <p className={cx(`activity-description`)}>
          {activityData.content}
        </p>
      </li>
    )
  }

  complete () {
    if (typeof this.props.onComplete === 'function') {
      this.props.onComplete ();
    }
  }

  render () {
    return (
      <main
        className={cx("container", "colonial")}
        style={this.props.style}>
        <ul className={cx(`activity-list`)}>
          {activities.map (this.activity)}
        </ul>
        <div className={cx(`complete-container`)}>
          <div
            className={cx("complete-button", "button", "h4")}
            onClick={this.complete}
          >
            next
          </div>
        </div>
      </main>
    )
  }
}

export default DayInTheLife;