class Timer {

  constructor () {
    this.frameId = null;
    this.startTime = undefined;

    this.plans = {}

    this._firstTick = this._firstTick.bind (this);
    this._tick = this._tick.bind (this);
    this.callEvents = this.callEvents.bind (this);
    this.at = this.at.bind (this);
    this.isOn = this.isOn.bind (this);
  }

  at (...events) {
    for (var {time, call} of events) {
      if (this.plans[time] === undefined) {
        this.plans[time] = [];
      }

      this.plans[time] = [...this.plans[time], call];
    }
  }

  getFps () {
    return this.framesPerSecond;
  }

  getDuration () {
    return this.duration;
  }

  isOn () {
    return this.frameId != null;
  }

  start () {
    // Check that the timer has not already been started
    if (this.frameId != null) {
      console.error ('Timer is already started');
      return;
    }
    
    // Call the infinitely recursive tick function
    this.frameId = window.requestAnimationFrame (this._firstTick);
  }

  stop () {
    // Check that the timer has been started
    if (this.frameId == null) {
      console.error ('Start must be called before stop');
      return;
    }

    // Cancel the next frame
    window.cancelAnimationFrame (this.frameId);
    this.frameId = null;
    this.startTime = undefined;
  }

  callEvents (curTimeStamp) {
    // Get a least first sorted array of the planned events
    const planTimes = Object.keys (this.plans).sort ((a, b) => (a - b));

    for (let i = 0; i < planTimes.length;  ++i) {
      // Whenever a future event is reached quit the loop
      if (planTimes[i] > curTimeStamp) break;

      const planTime = planTimes[i];
      const events = this.plans[planTime];
      events.forEach ((val) => {val ()});
      // After a plan is completed, delete it so it won't be reiterated over
      delete this.plans[planTime];
    }
  }

  _firstTick (startTimeStamp) {
    this.startTime = startTimeStamp;
    this._tick (startTimeStamp)
  }

  _tick (curTimeStamp) {
    this.callEvents (curTimeStamp - this.startTime)

    this.frameId = window.requestAnimationFrame (this._tick);
  }
}

export default Timer;