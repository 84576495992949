import React from 'react';
import YouTube from 'react-youtube';
import ShinolaQuote from 'components/shinola-quote';
import Timer from 'lib/timer';
import classnames from "classnames/bind"

// IMPORT LAST to benefit from cascading style overrides
import stylesheet from "./index.module.scss"
const cx = classnames.bind(stylesheet)

class Lecture extends React.Component {
  constructor () {
    super ();

    this.state = {
      playVideo : false
    }

    this.complete = this.complete.bind (this)
    this.setPlayer = this.setPlayer.bind (this);

    this.timer = new Timer ();
    this.timer.at ({time : 6000, call : this.playVideo.bind (this) })
    this.timer.start ();
  }

  componentWillUnmount () {
    if (this.timer.isOn ()) {
      this.timer.stop ();
    }
  }

  complete () {
    if (typeof this.props.onComplete === 'function') {
      this.props.onComplete ();
    }
  }

  playVideo () {
    this.timer.stop ();
    this.setState ({
      playVideo : true
    })
    if (this.player) this.player.playVideo ();
  }

  setPlayer ({target : player}) {
    this.player = player;
    if (this.state.playVideo) this.player.playVideo ();
  }

  render () {
    const embedOptions = {
      width: 1280,
      height: 720,
      playerVars : {
        color : 'white',
        fs : 0,
        iv_load_policy : 3,
        rel : 0,
        showinfo : 0
      }
    }

    return (
      <main className={cx(`page`)} style={this.props.style}>
        <section
          className={cx(`panis-quote-section`)}
          style={ {opacity : (this.state.playVideo ? 0 : 1)} }
        >
          <ShinolaQuote
            author="Jacques Panis"
            title="President of Shinola"
          >
            “STARTING UP A WATCH FACTORY IN DETROIT, JUST TO GIVE YOU A SENSE,
            <br/>
            DID NOT TAKE TWO GUYS SITTING ON THE OPPOSITE SIDES OF A BIG
            <br/>
            BOARDROOM TABLE. IT TOOK ONE WILD DRUNK INDIAN TO DO THIS.”
            <br/>
          </ShinolaQuote>
        </section>
        <section
          className={cx(`video-section`)}
          style={ {opacity : (this.state.playVideo ? 1 : 0)} }
        >
          <div className={cx(`video-container`)}>
            <div className={cx(`video-wrapper`)}>
              <YouTube
                className={cx(`youtube`)}
                videoId="zY7cFQHy0QI"
                onEnd={this.complete}
                onReady={this.setPlayer}
                opts={embedOptions}
              />
            </div>
          </div>
        </section>
      </main>
    )
  }
}

export default Lecture;