import React from 'react';
import classnames from "classnames/bind"

// IMPORT LAST to benefit from cascading style overrides
import stylesheet from "./index.module.scss"
const cx = classnames.bind(stylesheet)


const ShinolaQuote = ({children, author, title, href, className, id}) => {
  const Wrapper =  ({children, className, id}) => {
    return href ? (
      <a
        target="_blank"
        href={href}
        className={cx(className)}
        rel="noopener noreferrer"
        id={id}
      >
        {children}
      </a>
    ) : (
      <span className={cx(className)} id={id}>
        {children}
      </span>
    )
  }

  return (
    <Wrapper
      className={cx(className, "colonial", "container")}>
      <blockquote>
        <span className={`text`}>
          {children}
        </span>
      </blockquote>
      <br/>
      <cite>
        {author}
        <br/>
        <small>{title}</small>
      </cite>
    </Wrapper>
  )
}

export default ShinolaQuote;